import isMobile from 'helpers/isMobile';
import styleVariables from 'style/variables';

export function setIFrameDimensions(iFrameContainer, { widgetOpen, embedded, widgetFullWindow }) {
  if (embedded || widgetFullWindow) {
    iFrameContainer.contentWindow.document.body.style.margin = 0;
    iFrameContainer.contentWindow.document.body.style.padding = 0;

    return setIFrameSize(iFrameContainer, { width: '100%', height: '100%'});
  }

  if (!widgetOpen) {
    iFrameContainer.contentWindow.document.body.style.margin = 8;
    return setIFrameSize(iFrameContainer, { width: '150px', height: '60px'});
  }

  if (isMobile()) {
    return setIFrameSize(iFrameContainer, { width: '100%', height: '100%'});
  }

  return setIFrameSize(iFrameContainer, { width: styleVariables.mainIframeWidth, height: '636px'});
}

function setIFrameSize(iFrameContainer, { width, height }) {
  iFrameContainer.style.height = height;
  iFrameContainer.style.width = width;
}
