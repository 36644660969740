export default function(mutate) {

  const publicFunctions = {
    setWidgetId: (widgetID) => {
      mutate("widgetId", widgetID);
    },

    embeddedMode: (embedded = true) => {
      mutate("embedded", embedded);
      mutate("widgetOpen", true);
    },
  };

  const arrayLikeInterface = {
    push: (data) => {
      const [functionName, ...params] = data;
      return publicFunctions[functionName](...params);
    }
  };

  if (window.klaraWidget !== undefined) {
    window.klaraWidget.forEach((i) => arrayLikeInterface.push(i));
  }
  window.klaraWidget = arrayLikeInterface;
}
