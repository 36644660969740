const APP_NAME = 'Widget';
export const api = (url, options, base) => {
  return fetch(`${base}${url}`, options)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText || "An error has occurred!!");
    })
    .catch(error => {
      throw new Error(error);
    });

};

export const getTeamInfo = (widgetId) => (
  api(`/team_info?widget_id=${widgetId}&limit=3`, { method: 'GET' }, process.env.API_URL)
);
