const receiveMessage = (options) => (event) => {
  if (process.env.NODE_ENV === 'development') {
    console.log('Message from another window:', event);
  }

  const {
    setWidgetFullWindow,
  } = options;

  if (event.data.widgetSize) {
    switch (event.data.widgetSize) {
      case 'full-window':
        setWidgetFullWindow(true);
        return;
      case 'widget':
        setWidgetFullWindow(false);
        return;
      default:
        console.warn('Invalid widgetSize in message from another window:', event.data.widgetSize);
        return;
    }
  }

  console.warn('Invalid message from another window:', event);
};

const crossWindowApiListener = {
  startListening(options) {
    window.addEventListener("message", receiveMessage(options), false);
  }
};

export default crossWindowApiListener;
