import { h } from 'preact-cycle';
import classNames from 'classnames';
import styles from './styles';

export default ({ onClick, open, isMobile, dataTestId }) => {
  const buttonClasses = classNames(
    styles.button,
    { [styles.mobileButton]: isMobile },
  );

  if (open) {
    return (
      <div onClick={onClick} className={buttonClasses} data-test-id={ dataTestId }>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          className={styles.icon}
        >
          <path
            fill="#FFF5D1"
            fill-rule="evenodd"
            d="M13.41 11.995l8.294-8.286c.392-.392.392-1.027 0-1.42-.392-.391-1.027-.391-1.419 0L12 10.587 3.715 2.29c-.392-.392-1.027-.392-1.42 0-.391.392-.391 1.027 0 1.42l8.296 8.285-8.295 8.286c-.19.188-.296.443-.296.71 0 .266.106.522.296.71.187.188.443.295.71.295.266 0 .521-.107.709-.296L12 13.404l8.285 8.296c.188.19.443.296.71.296.266 0 .522-.107.71-.296.189-.187.295-.443.295-.71 0-.266-.106-.521-.296-.709l-8.295-8.286z"
          />
        </svg>
      </div>
    );
  }
  return (
    <div onClick={onClick} className={styles.open} data-test-id={ dataTestId } active>
      Message us
    </div>
  );
};
